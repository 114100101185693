import React from 'react'
import dynamic from 'next/dynamic'
import classNames from 'classnames'
import htmr from 'htmr'
import LazyLoad from 'react-lazyload'
import { EmptyObject } from 'types/helpers'
import { File } from 'types/contentful/restApi'
import { getLink } from '@helpers/env'
import { ButtonLink } from '@components/ui/Button'
import Badge, { BadgeProps } from '@components/ui/Badge'
import { ButtonLinkProps } from '@components/ui/Button/ButtonLink'
import { useFitFinderToggle } from '@components/FitFinder/hooks/useFitFinderToggle'
import styles from './styles.module.scss'

const ResponsiveHOC = dynamic(() => import('@components/ui/ResponsiveHOC'))

const formatText = (text = ''): React.ReactNode =>
  htmr(text.replace(/\\n/g, '<br>'))

function getIsFileVideoContent(file: string): boolean {
  return /mp4/.test(file)
}

type Align = 'start' | 'center' | 'end'

export type Variant = 'default' | 'full-bleed'

type Button =
  | Partial<{
      classes: string
      title: string
      text: string
      uri: string
      buttonStyle: ButtonLinkProps['variant']
      type: string
      event: Record<string, string>
    }>
  | EmptyObject

type Device =
  | {
      title: string
      description: string
      file: File
    }
  | EmptyObject

export type HeroBannerItemProps = {
  badge: BadgeProps
  desktop: Device
  desktopLarge: Device
  classes?: string
  sendEvent: (event: Button['event']) => void
  tablet: Device
  mobile: Device
  mobileGradient: boolean
  backgroundMode: 'bright' | 'dark'
  horizontalMobileDown: Align
  horizontalTabletDown: Align
  horizontalDesktopUp: Align
  verticalMobileDown: Align
  verticalTabletDown: Align
  verticalDesktopUp: Align
  title?: string
  subtitle?: string
  description?: string
  button: Button
  secondaryButton: Button
  imageOnly: boolean
  isFlashBannerVisible: boolean
  whitebackground: boolean
  mobileFillScreen: boolean
  variant: Variant
}

type BannerMediaProperties = {
  desktop: Device
  mobile: Device
  tablet: Device
  desktopLarge: Device
}

function BannerImage({
  desktopLarge,
  desktop,
  mobile,
  tablet
}: BannerMediaProperties) {
  return (
    <picture className="bannerItem-image">
      {desktopLarge?.file && (
        <source
          media="(min-width: 1600px)"
          srcSet={`${desktopLarge.file?.url}?fm=jpg&fl=progressive&w=3000`}
        />
      )}
      <source
        media={`(min-width: 1281px)${
          desktopLarge?.file ? ` and (max-width: 1599px)` : ''
        }`}
        srcSet={`${desktop.file.url}?fm=jpg&fl=progressive&w=2000`}
      />
      <source
        media="(min-width: 1000px) and (max-width: 1280px)"
        srcSet={`${desktop.file.url}?fm=jpg&fl=progressive&w=1400`}
      />
      <source
        media="(min-width: 600px) and (max-width: 1000px)"
        srcSet={`${tablet.file.url}?fm=jpg&fl=progressive&w=1000`}
      />
      <img
        alt={mobile.title}
        src={`${mobile.file.url}?fm=jpg&fl=progressive&w=600`}
      />
    </picture>
  )
}

function BannerVideo({
  mobile,
  tablet,
  desktop,
  desktopLarge
}: BannerMediaProperties) {
  return (
    <>
      <ResponsiveHOC maxWidth={599}>
        <video
          className="bannerItem-video"
          preload="metadata"
          autoPlay
          loop
          playsInline
          crossOrigin="anonymous"
          muted
        >
          <source src={mobile.file.url} type={mobile.file.contentType} />
        </video>
      </ResponsiveHOC>
      <ResponsiveHOC minWidth={600} maxWidth={999}>
        <video
          className="bannerItem-video"
          preload="metadata"
          autoPlay
          loop
          playsInline
          crossOrigin="anonymous"
          muted
        >
          <source src={tablet.file.url} type={tablet.file.contentType} />
        </video>
      </ResponsiveHOC>
      <ResponsiveHOC minWidth={1000} maxWidth={1599}>
        <video
          className="bannerItem-video"
          preload="metadata"
          autoPlay
          loop
          playsInline
          crossOrigin="anonymous"
          muted
        >
          <source src={desktop.file.url} type={desktop.file.contentType} />
        </video>
      </ResponsiveHOC>
      <ResponsiveHOC minWidth={1600}>
        <video
          className="bannerItem-video"
          preload="metadata"
          autoPlay
          loop
          playsInline
          crossOrigin="anonymous"
          muted
        >
          <source
            src={(desktopLarge || desktop)?.file.url}
            type={(desktopLarge || desktop)?.file.contentType}
          />
        </video>
      </ResponsiveHOC>
    </>
  )
}

function HeroBannerItem({
  classes,
  badge,
  desktopLarge,
  desktop,
  tablet,
  mobile,
  backgroundMode,
  title,
  subtitle,
  imageOnly,
  button,
  secondaryButton,
  sendEvent,
  mobileGradient,
  mobileFillScreen,
  horizontalMobileDown,
  horizontalTabletDown,
  horizontalDesktopUp,
  verticalMobileDown,
  verticalTabletDown,
  verticalDesktopUp,
  isFlashBannerVisible,
  whitebackground,
  description,
  variant
}: HeroBannerItemProps) {
  const { openFitFinder } = useFitFinderToggle()
  const bottomOffSetMobile = verticalMobileDown === 'end'
  const bottomOffSetTablet = verticalTabletDown === 'end'

  const handleClick = (
    ev: React.MouseEvent<HTMLAnchorElement>,
    clickedButton: Button
  ) => {
    if (ev.currentTarget.pathname.includes('/pages/fit-finder')) {
      ev.preventDefault()
      openFitFinder()
    }
    sendEvent(clickedButton.event)
  }

  const isVideo = getIsFileVideoContent(desktop.file.contentType)

  const topOffSet =
    verticalMobileDown === 'start' ||
    verticalTabletDown === 'start' ||
    verticalDesktopUp === 'start'

  const isButtonOnly = !title && !subtitle && !!button

  const bannerItemClasses = classNames(styles.bannerItem, {
    [styles.bannerItem_squished]: isFlashBannerVisible,
    [styles.bannerItemVerticalMobileDownBottom]:
      bottomOffSetMobile && !isButtonOnly,
    [styles.bannerItemVerticalTabletCenter]: !bottomOffSetTablet,
    [styles.bannerItem__FullBleed]: variant === 'full-bleed'
  })

  const Media = isVideo ? BannerVideo : BannerImage

  return (
    <div
      className={`${bannerItemClasses} ${classes} ${
        mobileFillScreen ? '' : styles.bannerItem__mobileStacked
      }`}
    >
      <div className={styles.bannerItem__media}>
        <LazyLoad once>
          <a
            href={getLink(button.uri)}
            onClick={ev => handleClick(ev, button)}
            className={styles.bannerItem__mediaLink}
          >
            <Media
              desktopLarge={desktopLarge}
              desktop={desktop}
              tablet={tablet}
              mobile={mobile}
            />
          </a>
        </LazyLoad>
      </div>
      {!imageOnly && (
        <div
          className={`
          ${styles.bannerItem__content}
          horizontal-${horizontalTabletDown}-tablet-only
          horizontal-${horizontalMobileDown}-mobile-down
          horizontal-${horizontalDesktopUp}-desktop-up
          vertical-${verticalTabletDown}-tablet-only
          vertical-${verticalMobileDown}-mobile-down
          vertical-${verticalDesktopUp}-desktop-up
          ${mobileGradient ? styles.bannerItem__content_gradient : ''}
          ${isButtonOnly ? styles.buttonOnly : ''}
          `}
        >
          <div
            className={`${styles.bannerItem__container} ${
              topOffSet ? styles.bannerItem__title_largeMargin : ''
            }`}
          >
            {!!badge && (
              <div className={styles.bannerItem__badge}>
                <Badge {...badge} />
              </div>
            )}

            <h1
              className={`${styles.bannerItem__title} ${
                backgroundMode ? `bannerItem__title--${backgroundMode}` : ''
              }
             `}
            >
              {formatText(title)}
            </h1>
            <h3
              className={`${styles.bannerItem__subtitle} ${
                backgroundMode ? `bannerItem__subtitle--${backgroundMode}` : ''
              }
              `}
            >
              {formatText(subtitle)}
            </h3>
            {!!description && (
              <p className={styles.bannerItem__description}>{description}</p>
            )}
            {(!!button?.uri || !!secondaryButton?.uri) && (
              <div className={styles.bannerItem__buttonsWrapper}>
                {button?.uri && (
                  <div className={styles.bannerItem__button}>
                    <ButtonLink
                      classes={button?.classes || ''}
                      variant={button?.buttonStyle || 'primary'}
                      href={button?.uri}
                      onClick={ev => handleClick(ev, button)}
                      fullWidth="always"
                    >
                      {button?.text}
                    </ButtonLink>
                  </div>
                )}
                {secondaryButton?.uri && (
                  <ButtonLink
                    classes={styles.bannerItem__button}
                    variant={whitebackground ? 'secondary' : 'white_outline'}
                    href={secondaryButton?.uri}
                    onClick={ev => handleClick(ev, secondaryButton)}
                    fullWidth="inherit"
                  >
                    {secondaryButton?.text}
                  </ButtonLink>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
export default HeroBannerItem
