import React, { useContext, useEffect } from 'react'
import { trackMessage } from '@helpers/trackError'
import { analytics } from '@helpers/analytics'
import { useRouter } from 'next/router'
import { getAnalyticsCountryCode } from '@helpers/env'
import { HIDE_FLASH_BANNER } from '@consts/index'
import { EmptyObject } from 'types/helpers'
import BannerItem, { HeroBannerItemProps, Variant } from './HeroBannerItem'
import styles from './styles.module.scss'
import AppContext from '@components/AppContext'

type BannerItem = {
  badge: HeroBannerItemProps['badge'] | EmptyObject
  imageOnly: HeroBannerItemProps['imageOnly']
  desktopLargeImage: HeroBannerItemProps['desktopLarge']
  desktopImage: HeroBannerItemProps['desktop']
  tabletImage: HeroBannerItemProps['tablet']
  mobileImage: HeroBannerItemProps['mobile']
  title: HeroBannerItemProps['title']
  subtitle: HeroBannerItemProps['subtitle']
  description: HeroBannerItemProps['description']
  button: HeroBannerItemProps['button']
  secondaryButton: HeroBannerItemProps['secondaryButton'] | EmptyObject
  horizontalMobileDown: HeroBannerItemProps['horizontalMobileDown']
  horizontalTabletDown: HeroBannerItemProps['horizontalTabletDown']
  horizontalDesktopUp: HeroBannerItemProps['horizontalDesktopUp']
  verticalMobileDown: HeroBannerItemProps['verticalMobileDown']
  verticalTabletDown: HeroBannerItemProps['verticalTabletDown']
  verticalDesktopUp: HeroBannerItemProps['verticalDesktopUp']
  mobileFillScreen: HeroBannerItemProps['mobileFillScreen']
  whitebackground: HeroBannerItemProps['whitebackground']
  type: string
  mobileGradient: boolean
}

type Images = {
  [id: number]: BannerItem
}

export type HeroBannerProps = {
  firstTimeUser: boolean
  rootPath: boolean
  images: Images | EmptyObject
  classes?: string
  variant: Variant
}

function HeroBanner({
  firstTimeUser,
  rootPath,
  images,
  classes = 'homepage',
  variant = 'default'
}: HeroBannerProps) {
  const router = useRouter()
  const { flashBanner } = useContext(AppContext)
  const bannerItem = Object.values(images)[0]

  const isFlashBannerVisible =
    (flashBanner.showBanner && !HIDE_FLASH_BANNER.includes(router.asPath)) ||
    false

  const backgroundMode = bannerItem?.whitebackground ? 'bright' : 'dark'
  const mobileGradient = bannerItem?.mobileGradient || false
  const mobileFillScreen =
    bannerItem?.mobileFillScreen != null ? bannerItem?.mobileFillScreen : true

  useEffect(() => {
    if (
      !bannerItem?.desktopImage ||
      !bannerItem?.tabletImage ||
      !bannerItem?.mobileImage
    ) {
      trackMessage('Missing img attributes')
    }
  }, [bannerItem])

  const sendEvent = (event: HeroBannerItemProps['button']['event']) => {
    if (event) {
      const type = rootPath ? { type: firstTimeUser ? 'New' : 'Existing' } : {}
      analytics('Click', {
        ...event,
        store_location: getAnalyticsCountryCode(),
        ...type
      })
    }
  }

  if (!bannerItem) {
    return null
  }

  const {
    badge,
    imageOnly,
    desktopLargeImage,
    desktopImage,
    tabletImage,
    mobileImage,
    title,
    subtitle,
    button = {},
    secondaryButton = {},
    horizontalMobileDown,
    horizontalTabletDown,
    horizontalDesktopUp,
    verticalMobileDown,
    verticalTabletDown,
    verticalDesktopUp,
    whitebackground,
    description
  } = bannerItem

  const props: HeroBannerItemProps = {
    classes,
    isFlashBannerVisible,
    badge,
    desktopLarge: desktopLargeImage,
    desktop: desktopImage,
    tablet: tabletImage,
    mobile: mobileImage,
    mobileGradient,
    backgroundMode,
    mobileFillScreen,
    horizontalMobileDown: horizontalMobileDown || 'center',
    horizontalTabletDown: horizontalTabletDown || 'center',
    horizontalDesktopUp: horizontalDesktopUp || 'start',
    verticalMobileDown: verticalMobileDown || 'end',
    verticalTabletDown: verticalTabletDown || 'end',
    verticalDesktopUp: verticalDesktopUp || 'center',
    title,
    subtitle,
    imageOnly,
    button,
    secondaryButton,
    sendEvent,
    whitebackground,
    description,
    variant
  }

  const handleBannerClick = () => {
    sendEvent({
      category: 'Homepage',
      label: 'Hero Banner'
    })
  }

  return (
    <section
      key="banner"
      className={`${styles.banner} banner--${classes} ${
        variant === 'full-bleed' ? styles.banner__FullBleed : ''
      }`}
    >
      <div
        className={styles.banner__container}
        onClick={handleBannerClick}
        onKeyDown={handleBannerClick}
        role="button"
        tabIndex={0}
      >
        <BannerItem {...props} />
      </div>
    </section>
  )
}

export default HeroBanner
